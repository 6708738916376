import React, { useState } from "react";
import { Link } from "gatsby";
import { Collapse } from "reactstrap";
import { StaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import Image from "gatsby-image";

const QueryComp = ({ isScrolled }) => {
    return (
      <StaticQuery
        query={graphql`
          query {
            allDataJson(filter: { id: { eq: "header" } }) {
              edges {
                node {
                  id
                  navItems {
                    text
                    type
                    link
                  }
                }
              }
            }
            companyInfo: allDataJson(filter: { id: { eq: "siteMeta" } }) {
              edges {
                node {
                  id
                  companyInfo {
                    phone
                    email
                  }
                }
              }
            }
            images: allFile(filter: { relativePath: { glob: "header/*.*" } }) {
              edges {
                node {
                  relativePath
                  name
                  childImageSharp {
                    fluid(maxWidth: 1800, quality: 75) {
                      ...GatsbyImageSharpFluid
                      presentationWidth
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => <Header data={data} isScrolled={isScrolled} />}
      />
    );
  };

  const Header = ({ data, isScrolled }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    let header = data.allDataJson.edges[0].node;
    let images = data.images.edges;
    let imageJms = images.find(img=>img.node.relativePath.search("header/logo") > -1 ).node.childImageSharp.fluid;

    function getHeaderClassName() {
      if (isScrolled) {
        return ["header-shadow", "header-small"].join(" ");
      }
      return [];
    }
    function getHeader() {
      let phone = header.navItems.filter(
        item => item.type && item.type === "tel"
      )[0];
      let phoneComp = (
        <li key="phone">
          <a href={"tel:" + phone.text} target="_blank">
            <span className="menu-item-span ti-mobile">&nbsp;{phone.text}</span>
          </a>
        </li>
      );
  
      let navItems = header.navItems
        .filter(item => item.link)
        .map(item => (
          <li key={item.text}>
            <Link activeClassName="active" to={t(item.link)}>
              <span className="menu-item-span"><strong>{t(item.text)}</strong></span>
            </Link>
          </li>
        ));
      return navItems.concat([phoneComp]);
    }
  
    return (
      <>
        <header className={"header header-transparent " + getHeaderClassName()}>
          <div className="container-fluid">
            <div className="inner-header">
              
              <div className="inner-brand" href="/"><Image fluid={imageJms}/></div>
            </div>
            <Collapse className="inner-navigation" isOpen={open}>
              <div className="inner-nav">
                <ul>{getHeader()}</ul>
              </div>
            </Collapse>
            <div className="extra-nav">
              <ul>
                <li className="nav-toggle">
                  <a
                    href="#"
                    onClick={() => setOpen(!open)}
                    data-target=".inner-navigation"
                  >
                    <span className="menu-item-span">
                      <i className="ti-menu" />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </>
    );
  };

 export default QueryComp;