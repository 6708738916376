import React from 'react';
import Helmet from 'react-helmet';
import Header from '../header/header';
import Footer from '../footer/footer';
import Scroll from "../utilities/detectScroll";

const Layout = (props) => (
    <>
        <Scroll>
            <Header/>
                {props.children}
            <Footer />
        </Scroll>
    </>
);

export default Layout;
