import React, { useEffect, useState } from "react";

const Scroll = props => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    window.onscroll = function() {
      if (window.pageYOffset > 5) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    return () => {
      window.onscroll = null;
    };
  });

  const children = React.Children.map(props.children, (child, index) => {
    return React.cloneElement(child, {
      index,
      isScrolled: isScrolled
    });
  });

  return <>{children}</>;
};
export default Scroll;
