import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
//import Facebook from "./facebook";

const QueryComp = props => {
  return (
    <StaticQuery
      query={graphql`
        query SEO {
          site {
            buildTime(formatString: "YYYY-MM-DD")
            siteMetadata {
              siteUrl
              defaultTitle: title
              defaultDescription: description
              defaultBanner: banner
              headline
              siteLanguage
              ogLanguage
              author
              facebook
            }
          }
        }
      `}
      render={data => <SEO site={data.site} {...props} />}
    />
  );
};

const SEO = ({ title, desc, banner, pathname, article, node, site }) => {
  const { t } = useTranslation();
  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      headline,
      siteLanguage,
      ogLanguage,
      author,
      // twitter,
      facebook
    }
  } = site;

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${t(siteUrl)}${banner || defaultBanner}`,
    url: `${t(siteUrl)}${pathname || ""}`
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: t(siteUrl),
    headline,
    inLanguage: t(siteLanguage),
    mainEntityOfPage: t(siteUrl),
    description: t(defaultDescription),
    name: t(defaultTitle),
    author: {
      "@type": "Person",
      name: author
    },
    copyrightHolder: {
      "@type": "Person",
      name: author
    },
    copyrightYear: "2019",
    creator: {
      "@type": "Person",
      name: author
    },
    publisher: {
      "@type": "Person",
      name: author
    },
    datePublished: "2019-01-18T10:30:00+01:00",
    dateModified: buildTime,
    image: {
      "@type": "ImageObject",
      url: `${t(siteUrl)}${defaultBanner}`
    }
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": t(siteUrl),
        name: "Homepage"
      },
      position: 1
    }
  ];

  let schemaArticle = null;

  if (article) {
    schemaArticle = {
      "@context": "http://schema.org",
      "@type": "Article",
      author: {
        "@type": "Person",
        name: author
      },
      copyrightHolder: {
        "@type": "Person",
        name: author
      },
      copyrightYear: new Date().getUTCFullYear(),
      creator: {
        "@type": "Person",
        name: author
      },
      publisher: {
        "@type": "Organization",
        name: author,
        logo: {
          "@type": "ImageObject",
          url: `${t(siteUrl)}${defaultBanner}`
        }
      },
      datePublished: node.first_publication_date,
      dateModified: node.last_publication_date,
      description: t(seo.description),
      headline: t(seo.title),
      inLanguage: t(siteLanguage),
      url: t(seo.url),
      name: t(seo.title),
      image: {
        "@type": "ImageObject",
        url: seo.image
      },
      mainEntityOfPage: t(seo.url)
    };
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      "@type": "ListItem",
      item: {
        "@id": t(seo.url),
        name: t(seo.title)
      },
      position: 2
    });
  }

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement
  };

  return (
    <>

      <Helmet title={t(seo.title)}>
        <meta name="title" content={t(seo.title)} />
        <html lang={t(siteLanguage)} />
        <meta name="description" content={t(seo.description)} />
        <meta name="image" content={seo.image} />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      
     
      {/* <Facebook
        desc={t(seo.description)}
        image={seo.image}
        title={t(seo.title)}
        type={article ? "article" : "website"}
        url={t(seo.url)}
        locale={t(ogLanguage)}
        name={facebook}
      /> */}
    </>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object
};
SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  node: null
};

export default QueryComp;
