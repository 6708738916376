import React from "react";
import { StaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
const QueryComp = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          companyInfo: allDataJson(filter: { id: { eq: "accueil" } }) {
            edges {
              node {
                sections {
                  id
                  title
                  description
                  description2
                  address
                  email
                  telephone
                  doneByLink
                  doneBy
                }
              }
            }
          }
        }
      `}
      render={data => <Footer data={data} />}
    />
  );
};

const Footer = ({ data }) => {
    let footer = data.companyInfo.edges[0].node.sections.find(
        section => section.id === "footer"
    );
  
    const { t } = useTranslation();
    return (
    <footer className="footer">
        <div className="footer-widgets">
            <div className="container">
                <div className="row">
                    <div className="col" align="center">
                        <aside className="widget widget-text">
                            <div className="widget-title">
                                <h6>{t(footer.title)}</h6><br/>
                                <h6>{t(footer.description)}</h6>
                            </div>
                            <div className="textwidget">
                                <p>{t(footer.description2)}</p>
                                <p>
                                    {t(footer.address)}<br/>
                                  
                                    <a href={`mail:${t(footer.email)}`}>{t(footer.email)}</a>
                                    <br />
                                   
                                    <a href={`tel:${t(footer.telephone)}`}>{t(footer.telephone)}</a>

                                </p>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bar">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="copyright">
                            <p>© {new Date().getFullYear()} <a href={t(footer.doneByLink)}>{t(footer.doneBy)}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
  };
  export default QueryComp;
