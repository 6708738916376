import React, { useEffect } from "react";
import Image from "gatsby-image";
import { jarallax } from "jarallax";
import { StaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
const QueryComp = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          companyInfo: allDataJson(filter: { id: { eq: "accueil" } }) {
            edges {
              node {
                sections {
                  id
                  config {
                    transition
                    images {
                      path
                      alt
                    }
                  }
                  titleMain {
                    text
                  }
                  titleSecond {
                    text
                  }
                  subtitle {
                    text
                  }
                }
              }
            }
          }
          images: allFile(filter: { relativePath: { glob: "hero/*.*" } }) {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 75) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <Hero data={data} {...props} />}
    />
  );
};

const Hero = ({ data, image, subtitle }) => {
    let mainHero = data.companyInfo.edges[0].node.sections.find(
        section => section.id === "mainHero"
    );
    let images = data.images.edges;
    let subtitleDefault = subtitle ? subtitle : mainHero.subtitle.text;
    let imgName = image ? image : "IMG_2344";
    let headerImg = data.images.edges.find(edge => edge.node.name === imgName).node.childImageSharp.fluid;
    let imageJms = images.find(img=>img.node.relativePath.search("hero/JMSimardEnter") > -1 ).node.childImageSharp.fluid;

    const { t } = useTranslation();
    useEffect(() => {
      jarallax(document.querySelectorAll(".parallax"), { speed: 0.2 });
    });
  
    return (
       <div className="wrapper">
          <section className={`module-cover parallax text-center ${
            imgName === "IMG_2344" ? "fullscreen" : ""
           }`}>
                <Image fluid={headerImg} className="header-background-image" style={{position:"absolute", opacity:0.5}}/>
                <div className="container">
                    {/* <Image fluid={imageJms} className="header-foreground-image"/> */}
                    <div className="col-md-12">
                        <h1 className="WhiteOnPicture m-b-20"><strong>{t(mainHero.titleMain.text)}<br/> {t(mainHero.titleSecond.text)}</strong></h1>
                        <h5 className="WhiteOnPicture m-b-40" style={{fontWeight:"bold"}}>{t(subtitleDefault)}</h5>
                    </div>
                </div>
            </section>
        </div>
    );
  };
  
  export default QueryComp;